import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCalendarAlt,
  faCalendarWeek,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import React from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Index.css";

import { baseUrl } from "../baseUrl";

const locales = {
  de: require("date-fns/locale/de/"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const messages = {
  allDay: "ganztätig",
  previous: <FontAwesomeIcon fixedWith={true} icon={faArrowLeft} />,
  next: <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />,
  today: <FontAwesomeIcon fixedWidth={true} icon={faClock} />,
  month: <FontAwesomeIcon fixedWidth={true} icon={faCalendarAlt} />,
  week: <FontAwesomeIcon fixedWidth={true} icon={faCalendarWeek} />,
  work_week: "Arbeitswoche",
  day: "Tag",
  agenda: "Agenda",
  date: "Datum",
  time: "Uhrzeit",
  event: "Event",
  showMore: (total) => `+ ${total} weitere Einträge`,
};

const style = {
  height: "80vh",
};

class LeaveCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      absences: [],
    };

    this.fetchAbsences = this.fetchAbsences.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  handleEventStyle(e) {
    let style;
    if (e.type === "sick") {
      style = {
        backgroundColor: "#ffcccb",
        color: "black",
      };

      return {
        style: style,
      };
    }

    style = {
      backgroundColor: "#ADD8E6",
      color: "black",
    };

    return {
      style: style,
    };
  }

  async fetchAbsences() {
    try {
      const url = `${baseUrl}/absences`;
      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      this.setState({
        absences: result,
      });
    } catch {
      this.setState({
        fetchError: true,
      });
    }
  }

  redirect(e) {
    this.props.history.push(`/mitarbeiter/bearbeiten/${e.owner}`);
  }

  componentDidMount() {
    this.fetchAbsences();
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Abwesenheitskalender</h1>
        </div>
        <React.Fragment>
          <Calendar
            startAccessor="from"
            endAccessor="to"
            eventPropGetter={this.handleEventStyle}
            localizer={localizer}
            culture="de"
            messages={messages}
            style={style}
            views={["month", "week"]}
            popup={true}
            events={this.state.absences}
            onDoubleClickEvent={this.redirect}
          />
        </React.Fragment>
      </div>
    );
  }
}

export default LeaveCalendar;
