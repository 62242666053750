import React from "react";
import { Link, Redirect } from "react-router-dom";

import { baseUrl } from "../baseUrl";

class EditVehicle extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      isDeleted: false,
      isSaved: false,
      vehicle: {},
    };

    this.deleteVehicle = this.deleteVehicle.bind(this);
    this.fetchVehicle = this.fetchVehicle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async deleteVehicle(e) {
    e.preventDefault();

    const id = this.props.match.params.id;

    try {
      const url = `${baseUrl}/vehicles/${id}`;

      await fetch(url, {
        method: "Delete",
        credentials: "include",
      });

      this.setState({
        isDeleted: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchVehicle() {
    const id = this.props.match.params.id;

    try {
      const url = `${baseUrl}/vehicles/${id}`;

      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const vehicle = await response.json();

      this.setState(vehicle);
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { description, registration } = this.state;

    const vehicle = {
      description,
      registration,
    };

    try {
      const id = this.props.match.params.id;
      const url = `${baseUrl}/vehicles/${id}`;

      await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(vehicle),
      });

      this.setState({
        isSaved: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchVehicle();
  }

  render() {
    return (
      <div className="container">
        {this.renderRedirect()}
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Fahrzeug bearbeiten</h1>
        </div>
        {this.renderError()}
        {this.renderForm()}
      </div>
    );
  }

  renderError() {
    const isError = this.state.isError;

    if (isError) {
      return (
        <div className="alert alert-danger" role="alert">
          Ein Fehler ist aufgetreten. Bitte laden Sie diese Seite neu.
        </div>
      );
    }
  }

  renderForm() {
    const { description, registration } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="description">Beschreibung</label>
          <input
            type="text"
            id="description"
            className="form-control"
            required={true}
            onChange={this.handleChange}
            value={description}
          />
        </div>

        <div className="form-group">
          <label htmlFor="registration">Kennzeichen</label>
          <input
            type="text"
            id="registration"
            className="form-control"
            onChange={this.handleChange}
            value={registration}
          />
        </div>

        <div className="d-flex">
          <button type="submit" className="btn btn-success">
            Speichern
          </button>
          <Link to="/fahrzeuge/" className="btn btn-secondary ml-3">
            {" "}
            Abbrechen
          </Link>
          <button className="btn btn-danger ml-auto" onClick={this.deleteVehicle}>
            Löschen
          </button>
        </div>
      </form>
    );
  }

  renderRedirect() {
    const { isDeleted, isSaved } = this.state;

    if (isDeleted || isSaved) {
      return <Redirect to="/fahrzeuge" />;
    }
  }
}

export default EditVehicle;
