import React from "react";
import { Route, Switch } from "react-router-dom";

import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

class ProjectIndex extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/projekte/bearbeiten/:id" component={Edit} />
        <Route path="/projekte/bearbeiten" component={Create} />
        <Route component={List} />
      </Switch>
    );
  }
}

export default ProjectIndex;
