import React from "react";
import { Link } from "react-router-dom";

import NewResourceButton from "../shared/NewResourceButton";
import { baseUrl } from "../baseUrl";

class ProjectList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
    };

    this.fetchProjects = this.fetchProjects.bind(this);
  }

  async fetchProjects() {
    try {
      const url = `${baseUrl}/projects`;

      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const projects = await response.json();

      this.setState({
        projects: projects,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchProjects();
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex align-items-center pb-2 mt-4 mb-4">
          <span>
            <h1>Bauvorhaben</h1>
          </span>
          <span>
            <NewResourceButton to={"projekte/bearbeiten"} />
          </span>
        </div>
        {this.renderProjects()}
      </div>
    );
  }

  renderProjects() {
    let projects = this.state.projects;

    if (!projects) {
      return;
    }

    projects = projects.map((x, i) => (
      <tr key={`project-${i}`}>
        <td>{x.description}</td>
        <td>{x.street}</td>
        <td>{x.postCode}</td>
        <td>{x.city}</td>
        <td>
          <Link
            to={`/projekte/bearbeiten/${x._id}`}
            className="btn btn-primary btn-sm"
          >
            Bearbeiten
          </Link>
        </td>
      </tr>
    ));

    return (
      <table className="table">
        <thead>
          <th>Beschreibung</th>
          <th>Straße & Hausnummer</th>
          <th>PLZ</th>
          <th>Ort</th>
          <th>Auswahl</th>
        </thead>
        <tbody>{projects}</tbody>
      </table>
    );
  }
}

export default ProjectList;
