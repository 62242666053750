import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faTruckMonster,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";

import React from "react";

import DeleteRessourceButton from "../DeleteRessourceButton";
import VehicleSelect from "../select/VehicleSelect";
import WorkerSelect from "../select/WorkerSelect";

import "./Day.css";

class Day extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editVehicles: false,
      editWorkers: false,
    };

    this.handleCopyRessources = this.handleCopyRessources.bind(this);
    this.toggleEditVehicles = this.toggleEditVehicles.bind(this);
    this.toggleEditWorkers = this.toggleEditWorkers.bind(this);
  }

  toggleEditVehicles() {
    this.setState((prevState) => ({
      editVehicles: !prevState.editVehicles,
    }));
  }

  toggleEditWorkers() {
    this.setState((prevState) => ({
      editWorkers: !prevState.editWorkers,
    }));
  }

  handleCopyRessources(e) {
    const day = this.props.day;
    this.props.handleCopyRessources(day);
  }

  render() {
    return (
      <React.Fragment>
        <div className="workers-row mb-3">{this.renderWorkerSection()}</div>
        <div className="vehicles-row mb-3">{this.renderVehicleSection()}</div>
        {this.props.day !== "saturday" && (
          <button
            className="rounded-pill btn btn-outline-success btn-sm"
            onClick={this.handleCopyRessources}
          >
            <FontAwesomeIcon fixedWidth={true} icon={faArrowAltCircleRight} />
          </button>
        )}
      </React.Fragment>
    );
  }

  renderWorkerSection() {
    const { day, workersDay } = this.props;

    if (!workersDay) {
      return;
    }

    const workers =
      workersDay.length === 0 ? (
        <span
          role="button"
          className="text-muted"
          onClick={this.toggleEditWorkers}
        >
          <button className="rounded-pill btn btn-outline-primary btn-sm">
            <FontAwesomeIcon fixedWidth={true} icon={faUserAlt} />
          </button>
        </span>
      ) : (
        this.renderWorkers(day, workersDay)
      );

    const select = this.state.editWorkers ? this.renderWorkerSelect(day) : "";

    return (
      <React.Fragment>
        {workers}
        {select}
      </React.Fragment>
    );
  }

  renderVehicleSection() {
    const { day, vehiclesDay } = this.props;

    if (!vehiclesDay) {
      return;
    }
    const vehicles =
      vehiclesDay.length === 0 ? (
        <span
          role="button"
          className="text-muted"
          onClick={this.toggleEditVehicles}
        >
          <button className="rounded-pill btn btn-outline-secondary btn-sm">
            <FontAwesomeIcon fixedWidth={true} icon={faTruckMonster} />
          </button>
        </span>
      ) : (
        this.renderVehicles(day, vehiclesDay)
      );

    const select = this.state.editVehicles ? this.renderVehicleSelect(day) : "";

    return (
      <React.Fragment>
        {vehicles}
        {select}
      </React.Fragment>
    );
  }

  renderWorkers(day, ressource) {
    const rows = ressource.map((x, i) => (
      <tr className="d-flex" key={i}>
        <span role="button" onClick={this.toggleEditWorkers}>
          {x.label}
        </span>
        <DeleteRessourceButton
          day={day}
          ressource={x}
          handleDelete={this.props.handleDeleteRessource}
        />
      </tr>
    ));

    return rows;
  }

  renderVehicles(day, ressource) {
    const rows = ressource.map((x, i) => (
      <tr className="d-flex" key={i}>
        <span role="button" onClick={this.toggleEditVehicles}>
          {x.label}
        </span>
        <DeleteRessourceButton
          day={day}
          ressource={x}
          handleDelete={this.props.handleDeleteRessource}
        />
      </tr>
    ));

    return rows;
  }

  renderVehicleSelect(day) {
    return (
      <VehicleSelect
        day={day}
        vehicles={this.props.vehicles}
        handleChange={this.props.handleVehicleChange}
        handleClose={this.toggleEditVehicles}
      />
    );
  }

  renderWorkerSelect(day) {
    return (
      <WorkerSelect
        day={day}
        selected={this.props.workersDay}
        workers={this.props.workers}
        handleChange={this.props.handleWorkerChange}
        handleClose={this.toggleEditWorkers}
      />
    );
  }
}

export default Day;
