import React from "react";
import Select from "react-select";

const START_TIMES = [
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:15",
  "09:30",
  "09:45",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00"
];

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: "black",
  }),
};


const StartTimeSelect = (props) => {
  const options = START_TIMES.map((x) => ({
    value: x, label: x
  }));

  return (
    <Select
      autoFocus={true}
      defaultMenuIsOpen={true}
      closeMenuOnSelect={true}
      isSearchable={true}
      placeholder={"Abfahrt..."}
      options={options}
      onBlur={props.handleClose}
      onChange={props.handleChange}
      styles={customStyles}
      value={{ value: props.startTime, label: props.startTime }}
    />
  );
};

export default StartTimeSelect;
