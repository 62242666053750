import React from "react";
import { Link } from "react-router-dom";

import NewResourceButton from "../shared/NewResourceButton";
import {baseUrl} from "../baseUrl";

class VehicleList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
    };

    this.fetchVehicles = this.fetchVehicles.bind(this);
  }

  async fetchVehicles() {
    try {
      const url = `${baseUrl}/vehicles`;

      const response = await fetch(url,{
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const vehicles = await response.json();

      this.setState({
        vehicles: vehicles,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchVehicles();
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex align-items-center pb-2 mt-4 mb-4">
          <span>
            <h1>Fahrzeuge</h1>
          </span>
          <span>
            <NewResourceButton to={"fahrzeuge/bearbeiten"} />
          </span>
        </div>
        {this.renderVehicles()}
      </div>
    );
  }

  renderVehicles() {
    let vehicles = this.state.vehicles;

    if (!vehicles) {
      return;
    }

    vehicles = vehicles.map((x, i) => (
      <tr key={`vehicle-${i}`}>
        <td>{x.description}</td>
        <td>{x.registration}</td>
        <td>
          <Link
            to={`/fahrzeuge/bearbeiten/${x._id}`}
            className="btn btn-primary btn-sm"
          >
            Bearbeiten
          </Link>
        </td>
      </tr>
    ));

    return (
      <table className="table">
        <thead>
          <th>Beschreibung</th>
          <th>Kennzeichen</th>
          <th>Auswahl</th>
        </thead>
        <tbody>{vehicles}</tbody>
      </table>
    );
  }
}

export default VehicleList;
