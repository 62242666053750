import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMinus } from "@fortawesome/free-solid-svg-icons";

import React from "react";

import Day from "./Day";
import { DAYS } from "../days";
import ProjectSelect from "../select/ProjectSelect";
import StartTimeSelect from "../select/StartTimeSelect";

import "./Row.css";

class CalendarRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editProject: false,
      editStartTime: false,
      editVehicle: false,
      editWorker: false,
    };

    this.handleCopyRessources = this.handleCopyRessources.bind(this);
    this.handleDeleteRessource = this.handleDeleteRessource.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleWorkerChange = this.handleWorkerChange.bind(this);

    this.toggleEditProject = this.toggleEditProject.bind(this);
    this.toggleEditStartTime = this.toggleEditStartTime.bind(this);
    this.toggleEditVehicle = this.toggleEditVehicle.bind(this);
    this.toggleEditWorker = this.toggleEditWorker.bind(this);
  }

  handleCopyRessources(day) {
    const row = this.props.id;
    this.props.handleCopyRessources(row, day);
  }

  handleDeleteRessource(day, ressource) {
    const projectId = this.props.id;
    this.props.handleDeleteRessource(projectId, day, ressource);
  }

  handleProjectChange(selected) {
    const row = this.props.id;
    const project = {
      id: selected.value,
      description: selected.label,
    };

    this.props.handleProjectChange(row, project);

    this.setState({
      editProject: false,
    });
  }

  handleStartTimeChange(startTime) {
    const id = this.props.id;
    this.props.handleStartTimeChange(id, startTime.value);
    this.toggleEditStartTime();
  }

  handleVehicleChange(vehicle, day) {
    const id = this.props.id;
    this.props.handleVehicleChange(id, day, vehicle);
  }

  handleWorkerChange(worker, day) {
    const id = this.props.id;
    this.props.handleWorkerChange(id, day, worker);
  }

  toggleEditProject() {
    this.setState((prevState) => ({
      editProject: !prevState.editProject,
    }));
  }

  toggleEditStartTime() {
    this.setState((prevState) => ({
      editStartTime: !prevState.editStartTime,
    }));
  }

  toggleEditVehicle() {
    this.setState((prevState) => ({
      editVehicle: !prevState.editVehicle,
    }));
  }

  toggleEditWorker() {
    this.setState((prevState) => ({
      editWorker: !prevState.editWorker,
    }));
  }

  render() {
    const id = this.props.id;

    return (
      <tr key={id}>
        <td>{this.renderProject()}</td>
        <td>{this.renderStartTime()}</td>
        {this.renderDays()}
        <td>
          <button
            className="rounded-pill btn btn btn-danger"
            onClick={() => this.props.handleDelete(id)}
          >
            <FontAwesomeIcon fixedWidth={true} icon={faMinus} />
          </button>
        </td>
      </tr>
    );
  }

  renderProject() {
    if (this.state.editProject) {
      return this.renderProjectSelect();
    }

    let project = "Bauvorhaben wählen";

    if (this.props.project) {
      project = (
        <React.Fragment>
          {this.props.project.description}
          <br />
          {this.props.project.street}
          <br />
          {this.props.project.postCode} {this.props.project.city}
        </React.Fragment>
      );
    }

    return (
      <span role="button" onClick={this.toggleEditProject}>
        {project}
      </span>
    );
  }

  renderProjectSelect() {
    if (!this.props.projects) {
      return;
    }

    const value = this.props.project ? this.props.project.description : " ";

    return (
      <ProjectSelect
        projects={this.props.projects}
        handleChange={this.handleProjectChange}
        handleClose={this.toggleEditProject}
        value={value}
      />
    );
  }

  renderStartTime() {
    if (this.state.editStartTime) {
      return this.renderStartTimeSelect();
    }

    const startTime = this.props.startTime ? (
      this.props.startTime
    ) : (
      <button className="ml-auto rounded-pill btn btn-sm btn-outline-secondary">
        <FontAwesomeIcon fixedWidth={true} icon={faClock} />
      </button>
    );

    return (
      <span role="button" onClick={this.toggleEditStartTime}>
        {startTime}
      </span>
    );
  }

  renderStartTimeSelect() {
    const startTime = this.props.startTime ? this.props.startTime : "";

    return (
      <StartTimeSelect
        startTime={startTime}
        handleChange={this.handleStartTimeChange}
        handleClose={this.toggleEditStartTime}
      />
    );
  }

  renderDays() {
    const days = DAYS.map((x, i) => (
      <td key={i} className="day">
        <Day
          day={x}
          vehiclesDay={this.props[x] && this.props[x].vehicles}
          workersDay={this.props[x] && this.props[x].workers}
          vehicles={this.props.vehicles}
          workers={this.props.workers}
          handleCopyRessources={this.handleCopyRessources}
          handleDeleteRessource={this.handleDeleteRessource}
          handleVehicleChange={this.handleVehicleChange}
          handleWorkerChange={this.handleWorkerChange}
        />
      </td>
    ));

    return days;
  }
}

export default CalendarRow;
