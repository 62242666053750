import React from "react";
import { Route, Switch } from "react-router";

import AbsenceIndex from "./components/absence/Index";
import { baseUrl } from "./components/baseUrl";
import CalendarIndex from "./components/calendar/desktop/Index";
import Footer from "./components/Footer";
import Login from './components/Login';
import Navigation from "./components/navigation/Index";
import ProjectIndex from "./components/project/Index";
import VehicleIndex from "./components/vehicle/Index";
import WorkerIndex from "./components/worker/Index";

class App extends React.PureComponent {

  async checkSession() {
    if (!navigator.onLine) {
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/session`, {
        credentials: "include",
      });

      if (response.status === 403) {
        localStorage.clear("user");

        window.location.href = "/"
      }

    } catch {
      this.setState({
        isAuthError: true
      });
    }
  }

  render() {
    const session = localStorage.getItem("user");
    const content = session
      ? this.renderAuthenticated()
      : this.renderAnonymous();

    return (
      <div className="App">
        {content}
        <Footer />
      </div>
    );
  }

  renderAnonymous() {
    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Route component={Login} />
      </Switch>
    )
  }

  renderAuthenticated() {
    this.checkSession();

    return (
      <React.Fragment>
        <Navigation />
        <div id="content">
          {this.renderContent()}
        </div>
      </React.Fragment>
    );
  }

  renderContent() {
    return (
      <Switch>
        <Route path="/abwesenheiten" component={AbsenceIndex} />
        <Route path="/mitarbeiter" component={WorkerIndex} />
        <Route path="/fahrzeuge" component={VehicleIndex} />
        <Route path="/projekte" component={ProjectIndex} />
        <Route component={CalendarIndex} />
      </Switch>
    );
  }
}

export default App;
