import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import React from "react";

const DeleteRessourceButton = (props) => {
  const handleDelete = () => {
    props.handleDelete(props.day, props.ressource);
  };

  return (
    <span
      role="button"
      className="ml-auto mr-2 text-muted"
      onClick={handleDelete}
    >
      <FontAwesomeIcon fixedWidth={true} icon={faTimes} />
    </span>
  );
};

export default DeleteRessourceButton;
