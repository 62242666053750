import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
import MediaQuery from "react-responsive";

import CalendarRow from "./Row";
import MobileIndex from "../mobile/Index";
import { baseUrl } from "../../baseUrl";
import { DAYS } from "../days";

import "./Calendar.css";

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendar: [],
      vehicles: [],
      workers: [],
    };

    this.addRow = this.addRow.bind(this);
    this.copyRessources = this.copyRessources.bind(this);
    this.deleteRow = this.deleteRow.bind(this);

    this.fetchCalendar = this.fetchCalendar.bind(this);
    this.fetchProjects = this.fetchProjects.bind(this);
    this.fetchVehicles = this.fetchVehicles.bind(this);
    this.fetchWorkers = this.fetchWorkers.bind(this);

    this.handleDeleteRessource = this.handleDeleteRessource.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleWorkerChange = this.handleWorkerChange.bind(this);

    this.submitRow = this.submitRow.bind(this);
  }

  addRow() {
    const blankRow = {
      project: "",
      startTime: "",
      monday: {
        vehicles: [],
        workers: [],
      },
      tuesday: {
        vehicles: [],
        workers: [],
      },
      wednesday: {
        vehicles: [],
        workers: [],
      },
      thursday: {
        vehicles: [],
        workers: [],
      },
      friday: {
        vehicles: [],
        workers: [],
      },
      saturday: {
        vehicles: [],
        workers: [],
      },
    };

    const calendar = [blankRow].concat(this.state.calendar);

    this.setState({
      calendar: calendar,
    });
  }

  copyRessources(row, day) {
    let rows = this.state.calendar;

    let index;
    let nextDay;
    let workers;
    let vehicles;

    for (let i = 0; i < rows.length; i++) {
      if (rows[i]._id === row) {
        workers = rows[i][day].workers;
        vehicles = rows[i][day].vehicles;

        index = i;

        for (let j = 0; j < DAYS.length; j++) {
          if (day === DAYS[j]) {
            nextDay = DAYS[j + 1];
          }
        }

        if (day === "friday") {
          rows[i]["monday"].workers = workers;
          rows[i]["monday"].vehicles = vehicles;
          break;
        }

        rows[i][nextDay].workers = workers;
        rows[i][nextDay].vehicles = vehicles;
        break;
      }
    }

    this.setState(
      {
        calendar: rows,
      },
      () => {
        this.submitRow(rows[index]._id, rows[index]);
      }
    );
  }

  async deleteRow(id) {
    try {
      const url = `${baseUrl}/rows/${id}`;

      await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      let calendar = this.state.calendar;

      for (let i = 0; i < calendar.length; i++) {
        if (calendar[i]._id === id) {
          calendar.splice(i, 1);
        }
      }

      this.setState({
        calendar: calendar,
      });
    } catch {
      this.setState({
        deleteEror: true,
      });
    }
  }

  async fetchCalendar() {
    try {
      const url = `${baseUrl}/rows`;

      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const calendar = await response.json();

      this.setState({
        calendar: calendar,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchProjects() {
    try {
      const url = `${baseUrl}/projects`;

      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const projects = await response.json();

      this.setState({
        projects: projects,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchVehicles() {
    try {
      const url = `${baseUrl}/vehicles`;

      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const vehicles = await response.json();

      this.setState({
        vehicles: vehicles,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchWorkers() {
    try {
      const url = `${baseUrl}/workers/available`;

      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const workers = await response.json();

      this.setState({
        workers: {
          available: workers.available,
          notAvailable: workers.notAvailable,
        },
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  handleDeleteRessource(projectId, day, ressource) {
    let rows = this.state.calendar;
    let j;

    for (let i = 0; i < rows.length; i++) {
      if (rows[i]._id === projectId) {
        rows[i][day].workers = rows[i][day].workers.filter(
          (x) => x !== ressource
        );
        rows[i][day].vehicles = rows[i][day].vehicles.filter(
          (x) => x !== ressource
        );

        j = i;
      }
    }

    this.setState(
      {
        calendar: rows,
      },
      () => {
        this.submitRow(rows[j]._id, rows[j]);
      }
    );
  }

  handleProjectChange(row, project) {
    let rows = this.state.calendar;
    let j;

    for (let i = 0; i < rows.length; i++) {
      if (rows[i]._id === row) {
        rows[i].project = project;
        j = i;
      }
    }

    this.setState(
      {
        calendar: rows,
      },
      () => {
        this.submitRow(rows[j]._id, rows[j]);
      }
    );
  }

  handleStartTimeChange(id, startTime) {
    let rows = this.state.calendar;
    let j;

    for (let i = 0; i < rows.length; i++) {
      if (rows[i]._id === id) {
        rows[i].startTime = startTime;
        j = i;
      }
    }

    this.setState(
      {
        calendar: rows,
      },
      () => {
        this.submitRow(rows[j]._id, rows[j]);
      }
    );
  }

  handleVehicleChange(id, day, vehicle) {
    let rows = this.state.calendar;
    let j;

    for (let i = 0; i < rows.length; i++) {
      if (rows[i]._id === id) {
        if (rows[i][day].vehicles.find((x) => x.value === vehicle.value)) {
          return;
        }

        rows[i][day].vehicles.push(vehicle);
        j = i;
      }
    }

    this.setState(
      {
        calendar: rows,
      },
      () => {
        this.submitRow(rows[j]._id, rows[j]);
      }
    );
  }

  handleWorkerChange(id, day, worker) {
    let rows = this.state.calendar;
    let j;

    for (let i = 0; i < rows.length; i++) {
      if (rows[i]._id === id) {
        if (rows[i][day].workers.find((x) => x.value === worker.value)) {
          return;
        }

        rows[i][day].workers.push(worker);
        j = i;
      }
    }

    this.setState(
      {
        calendar: rows,
      },
      () => {
        this.submitRow(rows[j]._id, rows[j]);
      }
    );
  }

  async submitRow(id, row) {
    if (!row) {
      return;
    }

    const body = {
      startTime: row.startTime,
      monday: row.monday,
      tuesday: row.tuesday,
      wednesday: row.wednesday,
      thursday: row.thursday,
      friday: row.friday,
      saturday: row.saturday,
    };

    if (row.project) {
      body.project = row.project.id;
    }

    try {
      let url = `${baseUrl}/rows/`;
      let method = "POST";

      if (row.created_at) {
        url = url + id;
        method = "PUT";
      }

      const response = await fetch(url, {
        method: method,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.status === 200) {
        this.setState(
          {
            isSaved: true,
          },
          // Fetch ressources after submit.
          () => {
            this.fetchCalendar();
            this.fetchWorkers();
            this.fetchVehicles();
          }
        );
      }
    } catch {
      this.setState({
        submitError: true,
      });
    }
  }

  fetchLoop() {
    setTimeout(() => {
      this.fetchCalendar();
      this.fetchProjects();
      this.fetchVehicles();
      this.fetchWorkers();

      this.fetchLoop();
    }, 5 * 60 * 1000);
  }

  componentDidMount() {
    this.fetchCalendar();
    this.fetchProjects();
    this.fetchVehicles();
    this.fetchWorkers();

    this.fetchLoop();
  }

  render() {
    return (
      <div>
        <MediaQuery minDeviceWidth={100} maxDeviceWidth={1199}>
          {this.renderMobile()}
        </MediaQuery>
        <MediaQuery minDeviceWidth={1200}>{this.renderDesktop()}</MediaQuery>
      </div>
    );
  }

  renderMobile() {
    return (
      <MobileIndex
        calendar={this.state.calendar}
        projects={this.state.projects}
        vehicles={this.state.vehicles}
        workers={this.state.workers}
        addRow={this.addRow}
        handleCopyRessources={this.copyRessources}
        handleDelete={this.deleteRow}
        handleDeleteRessource={this.handleDeleteRessource}
        handleExportPNG={this.exportPNG}
        handleProjectChange={this.handleProjectChange}
        handleStartTimeChange={this.handleStartTimeChange}
        handleVehicleChange={this.handleVehicleChange}
        handleWorkerChange={this.handleWorkerChange}
      />
    );
  }

  renderDesktop() {
    return (
      <div className="calendar-container" ref={this.calendarRef}>
        <table className="table table-striped table-dark table-sm  calendar-table">
          <colgroup span={1} style={{ width: "8%" }} />
          <colgroup span={1} style={{ width: "5.5%" }} />
          <colgroup span={1} style={{ width: "11%" }} />
          <colgroup span={1} style={{ width: "11%" }} />
          <colgroup span={1} style={{ width: "11%" }} />
          <colgroup span={1} style={{ width: "11%" }} />
          <colgroup span={1} style={{ width: "11%" }} />
          <colgroup span={1} style={{ width: "11%" }} />
          <colgroup span={1} style={{ width: "1%" }} />
          <thead
            className="thead-dark"
            style={{ position: "sticky", top: "0" }}
          >
            <tr>
              <th className="header-sticky" scope="col">
                Bauvorhaben
              </th>
              <th className="header-sticky" scope="col">
                Abfahrt
              </th>
              <th className="header-sticky" scope="col">
                Montag
              </th>
              <th className="header-sticky" scope="col">
                Dienstag
              </th>
              <th className="header-sticky" scope="col">
                Mittwoch
              </th>
              <th className="header-sticky" scope="col">
                Donnerstag
              </th>
              <th className="header-sticky" scope="col">
                Freitag
              </th>
              <th className="header-sticky" scope="col">
                Samstag
              </th>
              <th className="header-sticky">
                <button
                  className="rounded-pill btn btn btn-success"
                  onClick={this.addRow}
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
                </button>
              </th>
            </tr>
          </thead>

          <tbody className="calendar">{this.renderCalendarRows()}</tbody>
        </table>
      </div>
    );
  }

  renderCalendarRows() {
    if (
      !this.state.projects ||
      !this.state.workers ||
      !this.state.vehicles ||
      !this.state.calendar
    ) {
      return;
    }

    const calendar = this.state.calendar;
    const calendarRows = calendar.map((x, i) => (
      <CalendarRow
        key={i}
        id={x._id}
        project={x.project}
        startTime={x.startTime}
        monday={x.monday}
        tuesday={x.tuesday}
        wednesday={x.wednesday}
        thursday={x.thursday}
        friday={x.friday}
        saturday={x.saturday}
        projects={this.state.projects}
        vehicles={this.state.vehicles}
        workers={this.state.workers}
        handleCopyRessources={this.copyRessources}
        handleDelete={this.deleteRow}
        handleDeleteRessource={this.handleDeleteRessource}
        handleProjectChange={this.handleProjectChange}
        handleStartTimeChange={this.handleStartTimeChange}
        handleVehicleChange={this.handleVehicleChange}
        handleWorkerChange={this.handleWorkerChange}
      />
    ));
    return calendarRows;
  }
}

export default Calendar;
