import React from "react";
import { Link, Redirect } from "react-router-dom";

import { baseUrl } from "../baseUrl";

class CreateProject extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSaved: false,
      isError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { description, street, city, postCode } = this.state;

    const project = {
      description,
      street,
      city,
      postCode,
    };

    try {
      const url = `${baseUrl}/projects/`;

      await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(project),
      });

      this.setState({
        isSaved: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  render() {
    return (
      <div className="container">
        {this.renderRedirect()}
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Neues Bauvorhaben anlegen</h1>
        </div>
        {this.renderError()}
        {this.renderForm()}
      </div>
    );
  }

  renderError() {
    const isError = this.state.isError;

    if (isError) {
      return (
        <div className="alert alert-danger" role="alert">
          Ein Fehler ist aufgetreten. Bitte laden Sie diese  Seite neu.
        </div>
      );
    }
  }

  renderRedirect() {
    const isSaved = this.state.isSaved;

    if (isSaved) {
      return <Redirect to="/projekte/" />;
    }
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="description">Beschreibung</label>
          <input
            type="text"
            id="description"
            className="form-control"
            required={true}
            onChange={this.handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="street">Straße & Hausnummer</label>
          <input
            type="text"
            id="street"
            className="form-control"
            onChange={this.handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="postCode">Postleitzahl</label>
          <input
            type="text"
            id="postCode"
            className="form-control"
            onChange={this.handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="city">Ort</label>
          <input
            type="text"
            id="city"
            className="form-control"
            onChange={this.handleChange}
          />
        </div>

        <div className="d-flex">
          <button type="submit" className="btn btn-success ml-auto">
            Speichern
          </button>
          <Link to="/projekte/" className="btn btn-secondary ml-3">
            {" "}
            Abbrechen
          </Link>
        </div>
      </form>
    );
  }
}

export default CreateProject;
