import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="footer mt-3">
      <div className="container-fluid">
        <p>
          <small className="text-muted">
            Einsatzplanung 1.0
            <br />
          </small>
        </p>
      </div>
    </footer>
  );
};

export default Footer;