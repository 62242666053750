import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import React from "react";
import { Link } from "react-router-dom";

const NewButton = (props) => {
  return (
    <Link
      to={`/${props.to}`}
      className="rounded-pill btn btn-success ml-3"
    >
      <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
    </Link>
  );
};

export default NewButton;
