import React from "react";

import Logout from "../Logout";
import NavBrand from "../../brand-logo.png";

import "./Navigation.css";

class Navigation extends React.PureComponent {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light">
        <a className="navbar-brand" href="/">
          <img className="nav-image" src={NavBrand} alt="nav-brand" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">
                Einsatzplanung
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="/projekte/"
              >
                Bauvorhaben
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-item active dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Mitarbeiter
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/mitarbeiter/">
                  Stammdaten
                </a>
                <a className="dropdown-item" href="/abwesenheiten/">
                  Abwesenheitskalender
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="/fahrzeuge/"
              >
                Fahrzeuge
              </a>
            </li>
          </ul>

          <span className="ml-auto">
            <Logout />
          </span>
        </div>
      </nav>
    );
  }
}

export default Navigation;
