import React from "react";
import { Link, Redirect } from "react-router-dom";

import { baseUrl } from "../baseUrl";

class EditProject extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      isDeleted: false,
      isSaved: false,
      project: {},
    };

    this.deleteProject = this.deleteProject.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async deleteProject(e) {
    e.preventDefault();

    const id = this.props.match.params.id;

    try {
      const url = `${baseUrl}/projects/${id}`;

      await fetch(url, {
        method: "Delete",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      this.setState({
        isDeleted: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchProject() {
    const id = this.props.match.params.id;

    try {
      const url = `${baseUrl}/projects/${id}`;

      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const project = await response.json();

      this.setState(project);
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { description, street, city, postCode } = this.state;

    const project = {
      description,
      street,
      city,
      postCode,
    };

    try {
      const id = this.props.match.params.id;
      const url = `${baseUrl}/projects/${id}`;

      await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(project),
      });

      this.setState({
        isSaved: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchProject();
  }

  render() {
    return (
      <div className="container">
        {this.renderRedirect()}
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Bauvorhaben bearbeiten</h1>
        </div>
        {this.renderError()}
        {this.renderForm()}
      </div>
    );
  }

  renderError() {
    const isError = this.state.isError;

    if (isError) {
      return (
        <div className="alert alert-danger" role="alert">
          Ein Fehler ist aufgetreten. Bitte laden Sie diese Seite neu.
        </div>
      );
    }
  }

  renderForm() {
    const { description, street, city, postCode } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="description">Beschreibung</label>
          <input
            type="text"
            id="description"
            className="form-control"
            required={true}
            onChange={this.handleChange}
            value={description}
          />
        </div>

        <div className="form-group">
          <label htmlFor="street">Straße & Hausnummer</label>
          <input
            type="text"
            id="street"
            className="form-control"
            onChange={this.handleChange}
            value={street}
          />
        </div>

        <div className="form-group">
          <label htmlFor="postCode">Postleitzahl</label>
          <input
            type="text"
            id="postCode"
            className="form-control"
            onChange={this.handleChange}
            value={postCode}
          />
        </div>

        <div className="form-group">
          <label htmlFor="city">Ort</label>
          <input
            type="text"
            id="city"
            className="form-control"
            onChange={this.handleChange}
            value={city}
          />
        </div>

        <div className="d-flex">
          <button type="submit" className="btn btn-success">
            Speichern
          </button>
          <Link to="/projekte/" className="btn btn-secondary ml-3">
            {" "}
            Abbrechen
          </Link>
          <button
            className="btn btn-danger ml-auto"
            onClick={this.deleteProject}
          >
            Löschen
          </button>
        </div>
      </form>
    );
  }

  renderRedirect() {
    const { isDeleted, isSaved } = this.state;

    if (isDeleted || isSaved) {
      return <Redirect to="/projekte" />;
    }
  }
}

export default EditProject;
