import React from "react";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
} from "react-accessible-accordion";

import MobilePanel from "./Panel";

import "./Day.css"

const MobileDay = (props) => {
  const renderItems = () => {
    const item = props.projects.map((x) => (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            {x.description ? x.description :<span class="badge badge-secondary">Neues Bauvorhaben</span>} <br />
            {x.street} <br />
            {x.postCode} {x.city}
          </AccordionItemButton>
        </AccordionItemHeading>
        <MobilePanel
          day={props.day}
          project={x}
          projects={props.projectList}
          vehicles={props.vehicles}
          workers={props.workers}
          handleCopyRessources={props.handleCopyRessources}
          handleDelete={props.handleDelete}
          handleDeleteRessource={props.handleDeleteRessource}
          handleProjectChange={props.handleProjectChange}
          handleStartTimeChange={props.handleStartTimeChange}
          handleVehicleChange={props.handleVehicleChange}
          handleWorkerChange={props.handleWorkerChange}
        />
      </AccordionItem>
    ));
    return item;
  };
  return renderItems();
};

export default MobileDay;
