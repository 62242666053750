import React from "react";
import Select from "react-select";

const ProjectSelect = (props) => {
  /*   const options = props.projects.map((x) => (
    <option value={x.id}>{x.description}</option>
  )); */

  const options = props.projects.map((x) => ({
    value: x._id,
    label: x.description,
  }));

  const handleChange = (selected) => {
    if (!selected) {
      return;
    }

    props.handleChange(selected);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
    }),
  };
  
  return (
    <Select
      autoFocus={true}
      defaultMenuIsOpen={true}
      closeMenuOnSelect={true}
      isClearable={true}
      isSearchable={true}
      placeholder={"Bauvorhaben..."}
      options={options}
      onBlur={props.handleClose}
      onChange={handleChange}
      styles={customStyles}
    />
  );
};

export default ProjectSelect;
