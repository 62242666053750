import React from "react";
import Select from "react-select";

const VehicleSelect = (props) => {
  const available = props.vehicles.filter((x) => x[props.day] !== false);
  const options = available.map((x) => ({
    value: x._id,
    label: `${x.description} ${x.registration}`,
  }));

  const handleChange = (selected) => {
    if (!selected) {
      return;
    }

    props.handleChange(selected, props.day);
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  return (
    <Select
      autoFocus={true}
      defaultMenuIsOpen={true}
      isClearable={true}
      isSearchable={true}
      placeholder={"Fahrzeuge..."}
      options={options}
      onBlur={props.handleClose}
      onChange={handleChange}
      styles={customStyles}
    />
  );
};

export default VehicleSelect;
