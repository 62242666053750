import React from "react";
import { Link, Redirect } from "react-router-dom";

import AbsencePlanning from "./Absence";
import { baseUrl } from "../baseUrl";

class EditWorker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      isDeleted: false,
      isSaved: false,
      project: {},
      readyToDelete: false,
    };

    this.deleteWorker = this.deleteWorker.bind(this);
    this.fetchWorker = this.fetchWorker.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOtherAbsence = this.handleOtherAbsence.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
  }

  async deleteWorker(e) {
    e.preventDefault();
    const id = this.props.match.params.id;

    try {
      const url = `${baseUrl}/workers/${id}`;

      await fetch(url, {
        method: "Delete",
        credentials: "include",
      });

      this.setState({
        isDeleted: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchWorker() {
    const id = this.props.match.params.id;

    try {
      const url = `${baseUrl}/workers/${id}`;

      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const worker = await response.json();

      this.setState(worker);
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  handleOtherAbsence(e) {
    this.setState({
      otherAbsence: e.target.checked,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { name, otherAbsence } = this.state;

    const worker = {
      name,
      otherAbsence,
    };

    try {
      const id = this.props.match.params.id;
      const url = `${baseUrl}/workers/${id}`;

      await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(worker),
      });

      this.setState({
        isSaved: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  toggleDelete() {
    this.setState((prevState) => ({
      readyToDelete: !prevState.readyToDelete,
    }));
  }

  componentDidMount() {
    this.fetchWorker();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container d-print-none">
          {this.renderRedirect()}
          <div className="pb-2 mt-4 mb-4 border-bottom">
            <h1>Mitarbeiter bearbeiten</h1>
          </div>
          {this.renderError()}
          {this.renderForm()}
        </div>
        <AbsencePlanning
          worker={this.props.match.params.id}
          name={this.state.name}
        />
      </React.Fragment>
    );
  }

  renderError() {
    const isError = this.state.isError;

    if (isError) {
      return (
        <div className="alert alert-danger" role="alert">
          Ein Fehler ist aufgetreten. Bitte laden Sie diese Seite neu.
        </div>
      );
    }
  }

  renderForm() {
    const { name } = this.state;

    return (
      <form className="mb-3" onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            className="form-control"
            required={true}
            onChange={this.handleChange}
            value={name}
          />
        </div>

        <div class="mb-3 form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="otherAbsence"
            checked={this.state.otherAbsence}
            onChange={(e) => this.handleOtherAbsence(e)}
          />
          <label class="form-check-label" for="otherAbsence">
            Deaktiviert{" "}
            <small className="text-muted">
              (Mitarbeiter wird in Einsatzplanung als abwesend angezeigt)
            </small>
          </label>
        </div>

        <div className="d-flex">
          <button type="submit" className="btn btn-success">
            Speichern
          </button>
          <Link to="/mitarbeiter/" className="btn btn-secondary ml-3">
            {" "}
            Abbrechen
          </Link>
          {this.state.readyToDelete ? (
            <React.Fragment>
              <button
                className="btn btn-danger ml-auto"
                onClick={this.deleteWorker}
              >
                Löschen
              </button>
              <button
                className="btn btn-secondary ml-3"
                onClick={this.toggleDelete}
              >
                Abbrechen
              </button>
            </React.Fragment>
          ) : (
            <button
              className="btn btn-danger ml-auto"
              onClick={this.toggleDelete}
            >
              Löschen
            </button>
          )}
        </div>
      </form>
    );
  }

  renderRedirect() {
    const { isDeleted, isSaved } = this.state;

    if (isDeleted || isSaved) {
      return <Redirect to="/mitarbeiter" />;
    }
  }
}

export default EditWorker;
