import React from "react";
import { Redirect } from "react-router-dom";

import { baseUrl } from "./baseUrl";

class Logout extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isError: false,
            navigate: false
        };

        this.logout = this.logout.bind(this);
    }

    async logout(e) {
        e.preventDefault();

        try {
            await fetch(`${baseUrl}/users/logout`, {
                credentials: "include",
            });

            localStorage.clear("user");

            this.setState({
                navigate: true
            });
            
            window.location.href = "/";

        } catch {
            this.setState({
                isError: true
            });
        }
    }
    render() {
        const { navigate } = this.state;

        if (navigate) {
            return <Redirect to="/" push={true} />;
        }

        return (
            <button
                className="btn btn-outline-success my-2 my-sm-0"
                onClick={this.logout}
            >
                Abmelden
            </button>
        );
    }
}

export default Logout;
