import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTimes, faUserPlus } from "@fortawesome/free-solid-svg-icons";

import isFuture from "date-fns/isFuture";
import isToday from "date-fns/isToday";

import React from "react";
import { Link } from "react-router-dom";

import NewResourceButton from "../shared/NewResourceButton";
import { baseUrl } from "../baseUrl";

function isAbsent(worker) {
  if (worker.otherAbsence) {
    return true;
  }

  if (!worker.absentTill) {
    return false;
  }

  if (
    isToday(new Date(worker.absentTill)) ||
    isFuture(new Date(worker.absentTill))
  ) {
    return true;
  }
}

class WorkerList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
    };

    this.fetchWorkers = this.fetchWorkers.bind(this);
  }

  async fetchWorkers() {
    try {
      const url = `${baseUrl}/workers/available`;

      const response = await fetch(url, {
        credentials: "include",
      });
      let workers = await response.json();

      const available = workers.available.map((x) => {
        return {
          ...x,
          available: true,
        };
      });
      const notAvailable = workers.notAvailable.map((x) => {
        return {
          ...x,
          available: false,
        };
      });

      workers = available.concat(notAvailable).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.setState({
        workers: workers,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchWorkers();
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex align-items-center pb-2 mt-4 mb-4">
          <span>
            <h1>Mitarbeiter</h1>
          </span>
          <span>
            <NewResourceButton to={"mitarbeiter/bearbeiten"} />
          </span>
        </div>
        {this.renderWorkers()}
      </div>
    );
  }

  renderWorkers() {
    let workers = this.state.workers;

    if (!workers) {
      return;
    }

    workers = workers.map((x, i) => (
      <tr key={`worker-${i}`}>
        <td>{x.name}</td>
        <td>
          {x.available === false ? (
            <span className="text-danger">
              <FontAwesomeIcon fixedWidth={true} icon={faUserTimes} />
            </span>
          ) : (
            <span className="text-success">
              <FontAwesomeIcon fixedWidth={true} icon={faUserPlus} />
            </span>
          )}
        </td>
        <td>
          <Link
            to={`/mitarbeiter/bearbeiten/${x._id}`}
            className="btn btn-primary btn-sm"
          >
            Bearbeiten
          </Link>
        </td>
      </tr>
    ));

    return (
      <table className="table">
        <thead>
          <th>Name</th>
          <th>Verfügbarkeit</th>
          <th>Auswahl</th>
        </thead>
        <tbody>{workers}</tbody>
      </table>
    );
  }
}

export default WorkerList;
