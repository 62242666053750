import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faMinus,
  faPlus,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";

import React from "react";
import { Link } from "react-router-dom";

import DatePicker from "../DatePicker";
import { baseUrl } from "../baseUrl";

class AbsencePlanning extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      absences: [],
      from: new Date(),
      to: new Date(),
      type: "vacation",
    };

    this.fetchAbsences = this.fetchAbsences.bind(this);
    this.handleFrom = this.handleFrom.bind(this);
    this.handleTo = this.handleTo.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleFrom = (from) => {
    this.setState({
      from: from,
    });
  };

  handleTo = (to) => {
    this.setState({
      to: to,
    });
  };

  handleTypeChange(e) {
    e.preventDefault();

    this.setState({
      type: e.target.id,
    });
  }

  async deleteAbsence(e, id) {
    e.preventDefault();

    try {
      const url = `${baseUrl}/absences/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      this.fetchAbsences();

      this.setState({
        isDeleted: true,
        deleted: result,
      });
    } catch {
      this.setState({
        deleteError: true,
      });
    }
  }
  async fetchAbsences() {
    const id = this.props.worker;

    try {
      const url = `${baseUrl}/absences/${id}`;
      const response = await fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      this.setState({
        absences: result,
      });
    } catch {
      this.setState({
        fetchError: true,
      });
    }
  }

  async submit(e) {
    e.preventDefault();

    const absence = {
      owner: this.props.worker,
      type: this.state.type,
      from: this.state.from,
      to: this.state.to,
    };

    try {
      const url = `${baseUrl}/absences`;

      await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(absence),
      });

      this.fetchAbsences();

      this.setState({
        isSaved: true,
      });
    } catch {
      this.setState({
        submitError: true,
      });
    }
  }

  print(e) {
    e.preventDefault();
    window.print();
  }

  componentDidMount() {
    this.fetchAbsences();
  }

  render() {
    const printHeader =
      this.state.type === "vacation" ? "Urlaube" : "Abwesenheiten";
    return (
      <div className="container">
        <div className="d-print-none">
          <div className="pb-2 mt-4 mb-4 border-bottom">
            <h2>Abwesenheit eintragen</h2>
          </div>
          {this.renderForm()}
        </div>
        <h2 className="pb-2 mt-4 mb-4 d-none d-print-block">
          {printHeader + " " + this.props.name}
        </h2>
        {this.renderTable()}
      </div>
    );
  }

  renderForm() {
    const { from, to } = this.state;

    return (
      <form className="form-inline mb-3" onSubmit={this.submit}>
        <div className="form-group mr-sm-3 mb-2">
          {this.renderTypeSelection()}
        </div>
        <div className="form-group mr-sm-3 mb-2">
          <DatePicker date={from} handleChange={this.handleFrom} />
        </div>
        <div className="form-group mr-sm-3 mb-2">
          <DatePicker minDate={from} date={to} handleChange={this.handleTo} />
        </div>
        <button type="submit" className="btn btn-success mr-sm-auto mb-2">
          <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
        </button>
        <Link to="/abwesenheiten/" className="btn btn-primary mr-3 mb-2">
          <FontAwesomeIcon fixedWidth={true} icon={faCalendarAlt} />
        </Link>
        <button className="btn btn-secondary mb-2" onClick={this.print}>
          <FontAwesomeIcon fixedWidth={true} icon={faPrint} />
        </button>
      </form>
    );
  }

  renderTypeSelection() {
    const type = this.state.type;

    const buttonLabel = type === "vacation" ? "Urlaub" : "Arbeitsunfähigkeit";

    return (
      <div class="dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="typeSelection"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {buttonLabel}
        </button>
        <div className="dropdown-menu" aria-labelledby="typeSelection">
          <a
            id="vacation"
            className="dropdown-item"
            href="#"
            onClick={this.handleTypeChange}
          >
            Urlaub
          </a>
          <a
            id="sick"
            className="dropdown-item"
            href="#"
            onClick={this.handleTypeChange}
          >
            Arbeitsunfähigkeit
          </a>
        </div>
      </div>
    );
  }

  renderTable() {
    let absences = this.state.absences.filter(
      (x) => x.type === this.state.type
    );
    if (!absences) {
      return;
    }

    const items = absences.map((x, i) => (
      <tr key={i}>
        <td>{new Date(x.from).toLocaleDateString("de-DE")}</td>
        <td>{new Date(x.to).toLocaleDateString("de-DE")}</td>
        <td className="d-print-none">
          <button
            className="btn btn-danger"
            onClick={(e) => this.deleteAbsence(e, x._id)}
          >
            <FontAwesomeIcon fixedWidth={true} icon={faMinus} />
          </button>
        </td>
      </tr>
    ));

    return (
      <table className="table">
        <thead>
          <tr>
            <th>von</th>
            <th>bis</th>
            <th className="d-print-none">Auswahl</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    );
  }
}

export default AbsencePlanning;
