import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import Datepicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);

const DatePicker = (props) => {
  const date = () => {
    return new Date(props.date);
  };

  const minDate = () => {
    if (!props.minDate) {
      return;
    }

    return props.minDate;
  };

  return (
    <Datepicker
      locale="de"
      className="form-control"
      dateFormat="dd/MM/yyyy"
      minDate={minDate()}
      selected={date()}
      onChange={props.handleChange  }
      disabled={props.disabled}
    />
  );
};

export default DatePicker;
