import React from "react";
import Select from "react-select";

const WorkerSelect = (props) => {
  const available = props.workers.available.filter(
    (x) => x[props.day] !== false
  );
  const optionsAvailable = available.map((x) => ({
    value: x._id,
    label: x.name,
  }));

  const notAvailable = props.workers.notAvailable;
  const optionsNotAvailable = notAvailable.map((x) => ({
    value: x._id,
    label: x.name,
    isDisabled: false,
  }));

  const groupedOptions = [
    {
      label: "Verfügbar",
      options: optionsAvailable,
    },
    {
      label: "Abwesend",
      options: optionsNotAvailable,
    },
  ];

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  /*  Keep in case multi select is desired 
    const customMenu = ({ innerRef, innerProps, children }) => (
    <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
      <button
        className="btn btn-success btn-sm btn-block"
        onClick={props.handleClose}
      >
        Fertig
      </button>
      {children}
    </div>
  );
 */
  const handleChange = (selected) => {
    if (!selected) {
      return;
    }
    props.handleChange(selected, props.day);
  };

  const customStyles = {
    option: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? "lightgrey" : "black",
    }),
  };

  return (
    <Select
      // components={{ Menu: customMenu }}
      autoFocus={true}
      defaultMenuIsOpen={true}
      isClearable={true}
      isMulti={false}
      isSearchable={true}
      placeholder={"Mitarbeiter..."}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      onBlur={props.handleClose}
      onChange={handleChange}
      styles={customStyles}
    />
  );
};

export default WorkerSelect;
