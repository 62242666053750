import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faPlus } from "@fortawesome/free-solid-svg-icons";

import html2canvas from "html2canvas";
import { exportComponentAsPNG } from "react-component-export-image";
import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Accordion } from "react-accessible-accordion";
import { DAYS } from "../days";
import MobileDay from "./Day";

import "./Index.css";

const LOCALE_DAYS = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];

class MobileIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
    };

    this.calendarRef = React.createRef();

    this.exportPNG = this.exportPNG.bind(this);
  }

  async webShare(e) {
    e.preventDefault();
    html2canvas(document.body).then(function (canvas) {
      console.log("canvas", canvas)
      const canvasUrl = canvas.toDataURL();
      document.body.appendChild(canvas);
      console.log("ready2share")
      if (navigator.canShare()) {
        navigator
          .share({
            title: "`${postTitle} | ${siteTitle}`,",
            text: `test`,
            url: canvasUrl,
          })
          .then(() => {
            console.log('Successfully shared');
          })
          .catch(error => {
            console.error('Something went wrong sharing the blog', error);
          });
      }
    });
  }

  async exportPNG(e) {
    e.preventDefault();

    this.calendarRef.current.style.visibility = "visible";
    this.calendarRef.current.style.height = "100%";
    this.calendarRef.current.style.width = "100%";

    const date = new Date()
      .toLocaleString()
      .replace(/\,/g, "_")
      .replace(/\ /g, "")
      .replace(/\./g, "-");

    exportComponentAsPNG(this.calendarRef, {
      fileName: `${date}-Einsatzplanung.png`,
    });

    this.calendarRef.current.style.height = "0px";
    this.calendarRef.current.style.width = "0px";
    this.calendarRef.current.style.visibility = "hidden";
  }

  handleIndexChange(selected) {
    this.setState({ selectedIndex: selected });
  }

  render() {
    const selectedIndex = this.state.selectedIndex;

    return (
      <React.Fragment>
        <div className="container mobile-container mt-3">
          <Tabs
            selectedIndex={selectedIndex}
            onSelect={(selected) => this.handleIndexChange(selected)}
          >
            <TabList>
              <Tab>Mo</Tab>
              <Tab>Di</Tab>
              <Tab>Mi</Tab>
              <Tab>Do</Tab>
              <Tab>Fr</Tab>
              <Tab>Sa</Tab>
            </TabList>

            <span className="d-flex mt-3 mb-3">
              <h3>Bauvorhaben </h3>
              <span className="ml-auto">
                <button
                  className="rounded-pill btn btn-success"
                  onClick={this.props.addRow}
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
                </button>
                <button
                  id="download-btn"
                  className="rounded-pill btn btn-danger ml-3"
                  onClick={this.exportPNG}
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faFileImage} />
                </button>
              </span>
            </span>
            {this.renderDays()}
          </Tabs>
          {this.renderTable()}
        </div>
      </React.Fragment>
    );
  }

  renderTable() {
    const day = LOCALE_DAYS[this.state.selectedIndex];

    return (
      <div
        ref={this.calendarRef}
        style={{
          visibility: "hidden",
          height: "0px",
          width: "0px",
          overflow: "hidden",
        }}
      >
        <table
          className="table table-dark table-sm"
          style={{ fontSize: "1.35rem" }}
        >
          <thead className="thead-dark">
            <tr>
              <th>{day}</th>
            </tr>
          </thead>
          <tbody className="calendar">{this.renderCalendarRows()}</tbody>
        </table>
      </div>
    );
  }

  renderCalendarRows() {
    const calendar = this.props.calendar;
    const selectedIndex = this.state.selectedIndex;

    const calendarRows = calendar.map((x) =>
      this.renderRow(x.project, x.startTime, x[DAYS[selectedIndex]])
    );

    return calendarRows;
  }

  renderRow(project, startTime, ressources) {
    return (
      <tr>
        <td>
          <h5 className="mb-3">
            <b>{this.renderProject(project)}</b>
          </h5>
          <div className="mb-3">{this.renderStartTime(startTime)}</div>
          {this.renderRessources(ressources)}
        </td>
      </tr>
    );
  }

  renderProject(data) {
    let project = " ";

    if (data) {
      project = (
        <React.Fragment>
          {data.description}
          <br />
          {data.street}
          <br />
          {data.postCode} {data.city}
        </React.Fragment>
      );
    }

    return project;
  }

  renderStartTime(startTime) {
    return `Abfahrt: ${startTime}`;
  }

  renderRessources(ressources) {
    const workersList = ressources.workers.map((x) => <tr>{x.label}</tr>);
    const vehiclesList = ressources.vehicles.map((x) => <tr>{x.label}</tr>);
    return (
      <React.Fragment>
        <div className="mb-3">{workersList}</div>
        <div className="mb-3">{vehiclesList}</div>
      </React.Fragment>
    );
  }

  renderDays() {
    const calendar = this.props.calendar;
    const rows = calendar.map((x) => ({
      id: x._id,
      project: x.project,
      startTime: x.startTime,
      monday: x.monday,
      tuesday: x.tuesday,
      wednesday: x.wednesday,
      thursday: x.thursday,
      friday: x.friday,
      saturday: x.saturday,
    }));

    let days = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    };

    for (const row of rows) {
      for (const day of DAYS) {
        // Uncomment to filter projects w/o ressources
        /*         if (row[day].vehicles.length === 0 && row[day].workers.length === 0) {
          continue;
        } */

        days[day].push({
          startTime: row.startTime,
          rowId: row.id,
          ...row.project,
          vehicles: row[day].vehicles,
          workers: row[day].workers,
        });
      }
    }

    const items = DAYS.map((x) => (
      <TabPanel>
        <Accordion allowZeroExpanded={true}>
          <MobileDay
            day={x}
            projectList={this.props.projects}
            projects={days[x]}
            vehicles={this.props.vehicles}
            workers={this.props.workers}
            handleCopyRessources={this.props.handleCopyRessources}
            handleDelete={this.props.handleDelete}
            handleDeleteRessource={this.props.handleDeleteRessource}
            handleProjectChange={this.props.handleProjectChange}
            handleStartTimeChange={this.props.handleStartTimeChange}
            handleVehicleChange={this.props.handleVehicleChange}
            handleWorkerChange={this.props.handleWorkerChange}
          />
        </Accordion>
      </TabPanel>
    ));

    /*     const days = [];
    for (let i = 0; i < rows.length; i++) {
      console.log(rows[i]);
      for (const day of DAYS) {


        days.push(
          <TabPanel>
            <Accordion>
              <MobileDay
                day={day}
                rowId={rows[i].id}
                project={rows[i].project}
                startTime={rows[i].startTime}
                vehiclesDay={rows[i][day].vehicles}
                workersDay={rows[i][day].workers}
              />
            </Accordion>
          </TabPanel>
        );
      }
    } */

    return items;
  }
}

export default MobileIndex;
