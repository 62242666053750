import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faClock,
  faMinus,
  faToolbox,
  faTruckMonster,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";

import React from "react";
import { AccordionItemPanel } from "react-accessible-accordion";

import DeleteRessourceButton from "../DeleteRessourceButton";
import ProjectSelect from "../select/ProjectSelect";
import StartTimeSelect from "../select/StartTimeSelect";
import VehicleSelect from "../select/VehicleSelect";
import WorkerSelect from "../select/WorkerSelect";

class MobilePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copiedRessources: false,
      editProject: false,
      editStartTime: false,
      editVehicles: false,
      editWorkers: false,
    };

    this.handleCopyRessources = this.handleCopyRessources.bind(this);
    this.handleDeleteRessource = this.handleDeleteRessource.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleWorkerChange = this.handleWorkerChange.bind(this);
    this.toggleCopiedRessources = this.toggleCopiedRessources.bind(this);
    this.toggleEditProject = this.toggleEditProject.bind(this);
    this.toggleEditStartTime = this.toggleEditStartTime.bind(this);
    this.toggleEditVehicles = this.toggleEditVehicles.bind(this);
    this.toggleEditWorkers = this.toggleEditWorkers.bind(this);
  }

  handleCopyRessources() {
    const day = this.props.day;
    const row = this.props.project.rowId;
    this.props.handleCopyRessources(row, day);

    this.toggleCopiedRessources();
  }

  handleDeleteRessource(day, ressource) {
    const projectId = this.props.project.rowId;
    this.props.handleDeleteRessource(projectId, day, ressource);
  }

  handleProjectChange(selected) {
    const rowId = this.props.project.rowId;
    const project = {
      id: selected.value,
      description: selected.label,
    };

    this.props.handleProjectChange(rowId, project);

    this.setState({
      editProject: false,
    });
  }

  handleStartTimeChange(startTime) {
    const id = this.props.project.rowId;
    this.props.handleStartTimeChange(id, startTime.value);
  }

  handleVehicleChange(vehicle, day) {
    const id = this.props.project.rowId;
    this.props.handleVehicleChange(id, day, vehicle);
  }

  handleWorkerChange(worker, day) {
    const id = this.props.project.rowId;
    this.props.handleWorkerChange(id, day, worker);
  }

  toggleCopiedRessources() {
    this.setState(
      {
        copiedRessources: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            copiedRessources: false,
          });
        }, 5000);
      }
    );
  }

  toggleEditProject() {
    this.setState((prevState) => ({
      editProject: !prevState.editProject,
    }));
  }

  toggleEditStartTime() {
    this.setState((prevState) => ({
      editStartTime: !prevState.editStartTime,
    }));
  }

  toggleEditVehicles() {
    this.setState((prevState) => ({
      editVehicles: !prevState.editVehicles,
    }));
  }

  toggleEditWorkers() {
    this.setState((prevState) => ({
      editWorkers: !prevState.editWorkers,
    }));
  }

  render() {
    const project = this.props.project;

    return (
      <AccordionItemPanel>
        <div className="mobile-panel">
          <table className="table mobile-table">
            <tbody>
              {this.renderProjectSelect()}
              <br />
              {this.renderStartTime()}
              <br />
              {this.renderWorkerSection(project)}
              <br />
              {this.renderVehicleSection(project)}
              <br />
              {this.renderButtonMenu()}
              <br />
              {this.renderCopyWarning()}
            </tbody>
          </table>
        </div>
      </AccordionItemPanel>
    );
  }

  renderButtonMenu() {
    return (
      <span className="d-flex">
        <button
          className="rounded-pill btn btn btn-danger"
          onClick={() => this.props.handleDelete(this.props.project.rowId)}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faMinus} />
        </button>
        {this.props.day !== "saturday" && (
          <button
            className="ml-auto rounded-pill btn btn-success"
            onClick={this.handleCopyRessources}
          >
            <FontAwesomeIcon fixedWidth={true} icon={faArrowAltCircleRight} />
          </button>
        )}
      </span>
    );
  }

  renderCopyWarning() {
    if (!this.state.copiedRessources) {
      return;
    }

    return (
      <div class="alert alert-info" role="alert">
        Mitarbeiter und Fahrzeuge kopiert.
      </div>
    );
  }

  renderProjectSelect() {
    const value = this.props.project ? this.props.project.description : " ";
    if (this.state.editProject) {
      const projects = this.props.projects ? this.props.projects : [];

      return (
        <ProjectSelect
          projects={projects}
          handleChange={this.handleProjectChange}
          value={value}
        />
      );
    }

    return (
      <span className="d-flex">
        {value}
        <button
          className="ml-auto rounded-pill btn btn-primary"
          onClick={this.toggleEditProject}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faToolbox} />
        </button>
      </span>
    );
  }

  renderStartTime() {
    if (this.state.editStartTime) {
      return this.renderStartTimeSelect();
    }

    const startTime = `${this.props.project.startTime}`;

    return (
      <tr role="button" className="mb-3" onClick={this.toggleEditStartTime}>
        <span className="d-flex">
          {startTime}
          <button className="ml-auto rounded-pill btn btn-outline-secondary">
            <FontAwesomeIcon fixedWidth={true} icon={faClock} />
          </button>
        </span>
      </tr>
    );
  }

  renderStartTimeSelect() {
    const startTime = this.props.project.startTime
      ? this.props.project.startTime
      : "";

    return (
      <StartTimeSelect
        startTime={startTime}
        handleChange={this.handleStartTimeChange}
        handleClose={this.toggleEditStartTime}
      />
    );
  }

  renderWorkerSection(project) {
    const workers =
      project.workers.length === 0 ? (
        <span
          role="button"
          className="text-muted"
          onClick={this.toggleEditWorkers}
        >
          <span className="d-flex">
            <button className="ml-auto rounded-pill btn btn-outline-primary">
              <FontAwesomeIcon fixedWidth={true} icon={faUserAlt} />
            </button>
          </span>
        </span>
      ) : (
        this.renderWorkers(project)
      );

    const select = this.state.editWorkers ? this.renderWorkerSelect() : "";

    return (
      <React.Fragment>
        {workers}
        {select}
      </React.Fragment>
    );
  }

  renderWorkers(project) {
    const workers = project.workers.map((x) => (
      <tr className="d-flex">
        <span role="button" onClick={this.toggleEditWorkers}>
          {x.label}
        </span>
        <DeleteRessourceButton
          day={this.props.day}
          ressource={x}
          handleDelete={this.handleDeleteRessource}
        />
      </tr>
    ));
    return workers;
  }

  renderWorkerSelect() {
    return (
      <WorkerSelect
        day={this.props.day}
        selected={this.props.project.workers}
        workers={this.props.workers}
        handleChange={this.handleWorkerChange}
        handleClose={this.toggleEditWorkers}
      />
    );
  }

  renderVehicleSection(project) {
    const vehicles =
      project.vehicles.length === 0 ? (
        <span
          role="button"
          className="text-muted"
          onClick={this.toggleEditVehicles}
        >
          <span className="d-flex">
            <button className="ml-auto rounded-pill btn btn-outline-secondary">
              <FontAwesomeIcon fixedWidth={true} icon={faTruckMonster} />
            </button>
          </span>
        </span>
      ) : (
        this.renderVehicles(project)
      );

    const select = this.state.editVehicles ? this.renderVehicleSelect() : "";

    return (
      <React.Fragment>
        {vehicles}
        {select}
      </React.Fragment>
    );
  }

  renderVehicles(project) {
    const vehicles = project.vehicles.map((x) => (
      <tr className="d-flex">
        <span role="button" onClick={this.toggleEditVehicles}>
          {x.label}
        </span>
        <DeleteRessourceButton
          day={this.props.day}
          ressource={x}
          handleDelete={this.handleDeleteRessource}
        />
      </tr>
    ));
    return vehicles;
  }

  renderVehicleSelect() {
    return (
      <VehicleSelect
        day={this.props.day}
        vehicles={this.props.vehicles}
        handleChange={this.handleVehicleChange}
        handleClose={this.toggleEditVehicles}
      />
    );
  }
}

export default MobilePanel;
